import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    document.title = "聯絡我們 - 星玥護理";
  }, []);
  return (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1>聯絡我們</h1>
          <p class="lead">
            地址：Unit 02D,
            13/F,
            Hung Tat Industrial Building,
            No.43 Hung To Road,
            Kwan Tong, KLN
            觀塘鴻圖道43號
            鴻達工業大廈 13樓1302D室
            Kwun Tong
            <br />
            電話：95440002/94453331
            <br />
            傳真：21245498
            <br />
            Email：info@galaxycarehk.com
            <br />
            Facebook：Galaxy Care 星玥護理
          </p>
        </div>
      </div>
    </div>
  );
}
