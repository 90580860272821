import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect} from "react";
import { Form, Button, Card, Container, Col } from "react-bootstrap/";
import { Firebase } from "./App";

export default function About() {
const [institutionName, onChangeInstitutionName] = React.useState("");
const [address, onChangeAddress] = React.useState("");
const [region, onChangeRegion] = React.useState("請選擇地區");
const [phone, onChangePhone] = React.useState("");
const [email, onChangeEmail] = React.useState("");
const [password, onChangePassword] = React.useState("");
const [confirmPassword, onChangeConfirmPassword] = React.useState("");

useEffect(() => {
  document.title = "機構註冊 - 星玥護理";
}, []);

function register(event){
    event.preventDefault();
    if(institutionName.replace(/\s/g,"").length<=1){
        alert("機構名稱不能為空白")
    }else if(address.replace(/\s/g,"").length<=5){
        alert("地址不能少於4個字元")
    }else if(region=="請選擇地區"){
        alert("請從表單選擇地區")
    }else if(phone.replace(/[a-z,A-Z]/g).length!=8){
        alert("電話必需為8位數字")
    }else if(email.replace(/\s/g,"").length<=1){
        alert("電郵地址不能為空白")
    }else if(password.length<5){
        alert("密碼最少需為4個字元")
    }else if(password != confirmPassword){
        alert("密碼與確認密碼不符")
    }else{
        const institutionInfo ={
            name: institutionName,
            address: address,
            region: region,
            phone: phone,
            email: email
        };
        Firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(async(userCred)=>{
            await Firebase.firestore().collection("institutions")
            .add(institutionInfo)
            .catch((e) => {
              console.log("Create institution on firestore failed", e);
            });
        })
        .catch(e=>{
            alert(e)
        })
    }
}
  return (
    <div className="LoginBox">
      <Card>
        <Card.Body>
          <Container fluid>
            <Form onSubmit={(event)=>{register(event)}}>
              <Form.Group controlId="InstitutionName">
                <h2>Galaxy Care機構註冊</h2>
                <p>
                  歡迎註冊Galaxy
                  Care護理中介服務，我們提供各項醫療人才的提供服務，註冊後
                  貴公司可以透過我們的網站服務隨時預約員工。
                  <br />
                  您可以透過此表格註冊或者以電郵聯絡我們。
                  <br />{" "}
                  <a href="mailto:galaxycare2021@gmail.com">
                    galaxycare2021@gmail.com
                  </a>
                </p>
                <Form.Label>登記機構名稱</Form.Label>
                <Form.Control placeholder="請輸入公司的註冊名稱" value={institutionName} onChange={(event)=>{onChangeInstitutionName(event.target.value)}}/>
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridAddress">
                  <Form.Label>院社地址</Form.Label>
                  <Form.Control placeholder="請輸入院社中文地址" value={address} onChange={(event)=>{onChangeAddress(event.target.value)}}/>
                  <Form.Text className="text-muted">
                    若公司需為旗下院社註冊帳戶，煩請分別註冊以方便處理不同地區的訂單處理。
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridAddressRegion">
                  <Form.Label>所屬地區</Form.Label>
                  <Form.Control as="select" defaultValue="請選擇地區" value={region} onChange={(event)=>{onChangeRegion(event.target.value)}}>
                    <option>請選擇地區</option>
                    <option value="Kowloon">九龍</option>
                    <option value="New Territories">新界</option>
                    <option value="Hong Kong Island">港島</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>公司聯絡電話</Form.Label>
                <Form.Control
                  placeholder="請輸入公司的聯絡電話"
                  value={phone} onChange={(event)=>{onChangePhone(event.target.value)}}
                />

              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>公司電郵地址</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="請輸入公司的聯絡電郵地址（將用於公司登入）"
                  value={email} onChange={(event)=>{onChangeEmail(event.target.value)}}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>密碼</Form.Label>
                <Form.Control type="password" placeholder="密碼" value={password} onChange={(event)=>{onChangePassword(event.target.value)}} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>確認密碼</Form.Label>
                <Form.Control type="password" placeholder="確認密碼" value={confirmPassword} onChange={(event)=>{onChangeConfirmPassword(event.target.value)}}/>
              </Form.Group>
              <Button variant="primary" type="submit">
                登記
              </Button>
            </Form>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
}
