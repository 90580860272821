/* eslint-disable */

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react'
import { Container, Table } from "react-bootstrap/";
import { Firebase } from "./App";

export default function Balance() {
    const [requestsMadeThisMonth, setRequestsMadeThisMonth] = React.useState();
    const [currencyArray, setCurrencyArray] = React.useState();
    var sum = 0;
    useEffect(() => {
        document.title = "月結記錄 - 星玥護理";
      }, []);
    
      useEffect(async () => {
        const thisMonth = new Date().getMonth();
        const fetchedLocal = sessionStorage.getItem(`requestsOf${thisMonth}`);
        const jsonResolved = fetchedLocal != null ? JSON.parse(fetchedLocal) : [];
        setRequestsMadeThisMonth(jsonResolved);
        await fetchCurrency();
    }, [])

    async function fetchCurrency(){
        var tmpArray = [];
        await Firebase.firestore().collection("institution_variables").get()
        .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
                const mergingObject = {id: doc.id};
                const merged = Object.assign(doc.data(), mergingObject)
                tmpArray.push(merged)
            })
            setCurrencyArray([...tmpArray]);
        })
        .catch(e=>{
            console.log("Error fetching currency", e)
        })
    }

    function calculateSalary(rank, hours){
        var ref;
        switch (rank) {
            case "RN":
              ref = "RN_Salary";
              break;
            case "EN":
              ref = "EN_Salary";
              break;
            case "HW":
              ref = "HW_Salary";
              break;
            case "PCW":
              ref = "PCW_salary";
              break;
            case "WM":
              ref = "WM_salary";
              break;
          }
        const salaryDoc = currencyArray.filter(doc=>doc.id == ref)[0];
        sum += parseInt(salaryDoc[hours]);
        return salaryDoc[hours];
    }
    return (
        <div>
            {requestsMadeThisMonth && currencyArray?
                <Container>
                    <h2 class="mt-3">預期月結記錄</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>工作日期</th>
                                <th>職位</th>
                                <th>時數</th>
                                <th>資薪</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestsMadeThisMonth.filter(request => request.confirmed).map(request => {
                                return (
                                    <tr>
                                        <td>{`${new Date(request.startTime.seconds * 1000).getFullYear()}年${new Date(request.startTime.seconds * 1000).getMonth() + 1
                                            }月${new Date(request.startTime.seconds * 1000).getDate()}日${new Date(request.startTime.seconds * 1000).getHours() < 10 ? '0' + new Date(request.startTime.seconds * 1000).getHours() : new Date(request.startTime.seconds * 1000).getHours()}時${new Date(request.startTime.seconds * 1000).getMinutes() < 10 ? '0' + new Date(request.startTime.seconds * 1000).getMinutes() : new Date(request.startTime.seconds * 1000).getMinutes()}`}分-<br />{`${new Date(request.endTime.seconds * 1000).getFullYear()}年${new Date(request.endTime.seconds * 1000).getMonth() + 1
                                                }月${new Date(request.endTime.seconds * 1000).getDate()}日${new Date(request.endTime.seconds * 1000).getHours() < 10 ? '0' + new Date(request.endTime.seconds * 1000).getHours() : new Date(request.endTime.seconds * 1000).getHours()}時${new Date(request.endTime.seconds * 1000).getMinutes() < 10 ? '0' + new Date(request.endTime.seconds * 1000).getMinutes() : new Date(request.endTime.seconds * 1000).getMinutes()}分`}</td>
                                        <td>{request.rank}</td>
                                        <td>{Math.ceil(
                                            (new Date(request.endTime.seconds * 1000).getTime() -
                                            new Date(request.startTime.seconds * 1000).getTime())
                                         / 36e5)}</td>
                                        <td>{calculateSalary(request.rank, Math.ceil(
                                            (new Date(request.endTime.seconds * 1000).getTime() -
                                            new Date(request.startTime.seconds * 1000).getTime())
                                         / 36e5))}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <h4 class="float-right">預期支出:${sum}港幣</h4>
                </Container> : null}
        </div>
    )
}