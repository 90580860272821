/* eslint-disable */

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Row, Card, Image } from "react-bootstrap/";
import React, { useEffect } from "react";
import { Firebase } from "./App";
import firebase from 'firebase';
import avatar from "./Default_Avatar.png";
import femaleAvatar from "./femaleAvatar.png"

//Reminder Added

export default function JobCalendar() {
  const today = new Date();
  const [renderForm, toggleRenderForm] = React.useState(true);
  const [queryMonth, setMonth] = React.useState(new Date().getMonth()); //In 0-based
  const [queryYear, setYear] = React.useState(new Date().getFullYear());
  const [queryDay, setQueryDay] = React.useState(new Date().getDate());
  const [calendarArray, buildCalendarArray] = React.useState([]);
  const [requiredRank, setRequiredRank] = React.useState("選擇所需員工");
  const [startHour, setStartHour] = React.useState("");
  const [startMinute, setStartMinute] = React.useState("");
  const [endHour, setEndHour] = React.useState("");
  const [endMinute, setEndMinute] = React.useState("");
  const [remarks, setRemarks] = React.useState("");
  const [requestArray, setRequestArray] = React.useState([]);
  const [fetchArray, setFetchArray] = React.useState([]);

  useEffect(() => {
    document.title = "指派工作 - 星玥護理";
  }, []);

  function nextMonth() {
    setFetchArray([]);
    if (queryMonth == 11) {
      setMonth(0);
      setYear(queryYear + 1);
      setQueryDay(1);
    } else {
      setMonth(queryMonth + 1);
      setQueryDay(1);
    }
  }

  function previousMonth() {
    setFetchArray([]);
    if (queryMonth == 0) {
      setMonth(11);
      setYear(queryYear - 1);
      setQueryDay(1);
    } else {
      setMonth(queryMonth - 1);
      setQueryDay(1);
    }
  }

  function selectQueryDay(day) {
    if (day != "image") {
      setQueryDay(day);
    }
  }

  function fullRankTitle(rank) {
    switch (rank) {
      case "RN":
        return "註冊護士(RN)"
      case "EN":
        return "登記護士(EN)"
      case "HCA":
        return "健康服務助理(HCA)"
      case "PCW":
        return "起居照顧員(PCW)"
      case "HW":
        return "保健員(HW)"
      case "WM":
        return "常務員(WM)"
    }
  }

  function pushArray() {
    const startDateObject = new Date(
      queryYear,
      queryMonth,
      queryDay,
      startHour,
      startMinute
    );
    const endDateObject = new Date(
      queryYear,
      queryMonth,
      queryDay,
      endHour,
      endMinute
    );

    if (requiredRank == "選擇所需員工") {
      alert("請先選擇所需員工");
    } else if (
      startHour == "" ||
      startMinute == "" ||
      endHour == "" ||
      endMinute == ""
    ) {
      alert("請先填選上班及下班時間");
    } else if (
      startHour > 23 ||
      endHour > 23 ||
      startMinute > 59 ||
      endMinute > 59
    ) {
      alert("時間格式有誤");
    } else if (startDateObject.getTime() < today.getTime()) {
      alert("不能建立早於今天的指令");
    } else if (startDateObject.getTime > endDateObject.getTime()) {
      alert("開始時間不能晚於結束時間");
    } else {
      const tmpPushRequestArray = requestArray;
      const requestInfo = {
        rank: requiredRank,
        startTime: startDateObject,
        endTime: endDateObject,
        remarks: remarks
      };
      if (requestArray.length > 0) {
        alert(`您確定要新增${startDateObject}?`)
      }
      setRequestArray([...tmpPushRequestArray, requestInfo]);
    }
  }

  async function getInstitutionInfoAndPostJobRequest() {
    await Firebase.firestore()
      .collection("institutions")
      .where("email", "==", Firebase.auth().currentUser.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          postJobRequest(doc.data());
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function postJobRequest(fetchInstitutionData) {
    const jobInfo = {
      institutionName: fetchInstitutionData.name,
      institutionUID: Firebase.auth().currentUser.uid,
      institutionRegion: fetchInstitutionData.region,
      institutionPhone: fetchInstitutionData.phone,
      address: fetchInstitutionData.address,
      confirmed: false,
      recruiting: true,
      appliedMembers: [],
      recruitedMembers: [],
      builtTime: new Date()
    };

    const jobPostArray = requestArray.map((request) => {
      return Object.assign(request, jobInfo);
    });

    jobPostArray.forEach((jobPost) => {
      Firebase.firestore().collection("jobs").add(jobPost)
    });
    alert("招聘已發佈!")
    setRequestArray([]);
    fetchAllRequest();
  }

  function removeItem(index) {
    var tmpRequestArray = requestArray;
    tmpRequestArray.splice(index, index + 1);
    console.log(tmpRequestArray);
    setRequestArray([...tmpRequestArray]);
  }

  async function recruitMember(jobID, member) {
    Firebase.firestore().collection("jobs").doc(jobID)
      .update({
        recruitedMembers:
          firebase.firestore.FieldValue.arrayUnion(member),
      })
      .then(() => {
        alert("成功配對，我們將與員工確認。");
        Firebase.firestore().collection("memo")
          .add({
            jobID: jobID,
            contactNumber: member.phone,
            memberName: member.fullname
          })
      })
  }

  async function pauseRecruiting(jobID) {
    Firebase.firestore().collection("jobs").doc(jobID)
      .update({ recruiting: false })
      .then(() => {
        alert("已暫停招聘")
        fetchAllRequest();
      })
  }

  async function deleteRecruiting(jobID) {
    if (window.confirm("確定要刪除此招聘嗎?")) {
      Firebase.firestore().collection("jobs").doc(jobID)
        .delete()
        .then(() => {
          alert("招聘已刪除")
          fetchAllRequest();
        })
    } else {
      return;
    }
  }

  async function fetchAllRequest() {
    const queryEndScope = new Date(queryYear, queryMonth + 1, 1)
    const queryStartScope = new Date(queryYear, queryMonth, 1)
    var tmpRequestContainer = [];
    Firebase.firestore().collection("jobs")
      .where("institutionUID", "==", Firebase.auth().currentUser.uid)
      .where("startTime", "<=", queryEndScope)
      .where("startTime", ">=", queryStartScope)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const objectToBeMerged = { jobID: doc.id };
          const mergedObject = Object.assign(doc.data(), objectToBeMerged)
          tmpRequestContainer.push(mergedObject);
        })
        setFetchArray([...tmpRequestContainer]);
        const jsonValue = JSON.stringify(tmpRequestContainer)
        sessionStorage.setItem(`requestsOf${queryMonth}`, jsonValue)
        console.log("Fetched Data", fetchArray)
      })
  }

  React.useEffect(async () => {
    function calendarDateArray() {
      const numOfDaysInMonth = new Date(queryYear, queryYear, 0).getDate();
      const weekdayOfFirstDayInMonth = new Date(
        queryYear,
        queryMonth,
        1
      ).getDay();
      //Building 6 x 7 2D Array
      var sixWeeksArrayFrame = new Array(6);
      for (var i = 0; i < sixWeeksArrayFrame.length; i++) {
        sixWeeksArrayFrame[i] = new Array(7);
      }
      var day = 1;
      for (var i = 0; i < sixWeeksArrayFrame.length; i++) {
        for (var j = 0; j < sixWeeksArrayFrame[i].length; j++) {
          if (i == 0 && j < weekdayOfFirstDayInMonth) {
            sixWeeksArrayFrame[i][j] = null;
          } else if ((i == 5) & (j == 6)) {
            sixWeeksArrayFrame[i][j] = "image";
          } else if (day > numOfDaysInMonth) {
            sixWeeksArrayFrame[i][j] = null;
          } else {
            sixWeeksArrayFrame[i][j] = day;
            day++;
          }
        }
      }
      buildCalendarArray(sixWeeksArrayFrame);
    }
    await fetchAllRequest();
    calendarDateArray();
  }, [queryMonth, queryYear]);
  return (
    <div className="CalendarBackground">
      <div className="CalendarContainer">
        <div className="MonthRow">
          <Button variant="" onClick={() => previousMonth()}>
            ←
          </Button>
          <h3 class="m-3">
            {queryYear}年{queryMonth + 1}月
          </h3>
          <Button variant="" onClick={() => nextMonth()}>
            →{" "}
          </Button>
        </div>
        <Row className="WeekdaysRow ml-2 mr-2">
          <h5>日</h5>
          <h5>一</h5>
          <h5>二</h5>
          <h5>三</h5>
          <h5>四</h5>
          <h5>五</h5>
          <h5>六</h5>
        </Row>
        {calendarArray.map((weekArray) => {
          return (
            <div className="WeekRow ml-2 mr-2">
              {weekArray.map((day) => {
                return (
                  <button
                    className={day == queryDay ? "QueryDayBox" : "DayBox"}
                    disabled={!day}
                    onClick={() => {
                      selectQueryDay(day);
                      if (fetchArray.filter(request => new Date(request.startTime.seconds * 1000).getDate() == day).length > 0) {
                        toggleRenderForm(false)
                      } else {
                        toggleRenderForm(true);
                      }
                    }}
                  >
                    {day == "image" ? null : day}
                    {fetchArray.filter(request => new Date(request.startTime.seconds * 1000).getDate() == day).map(request => {
                      return (
                        <div className={request.appliedMembers.length == 0 ? "RecruitingLabel" : request.confirmed ? "RecruitedLabel" : "PendingDecisionLabel"}>{request.appliedMembers.length == 0 ? "⌛" : request.confirmed ? "✅" : "💬"}</div>
                      )
                    })}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="OrderFormContainer">
        <div className="ButtonOptionRow">
          <Button
            variant="info"
            className="float-right ml-2"
            onClick={() => {
              toggleRenderForm(false);
            }}
          >
            指派狀態
          </Button>
          <Button
            variant="info"
            className="float-right"
            onClick={() => {
              toggleRenderForm(true);
            }}
          >
            指派工作
          </Button>
        </div>
        {renderForm ? (
          <div className="FormContainer">
            <Form>
              <Form.Group>
                <Form.Label>指派日期</Form.Label>
                <br />
                <Form.Label>
                  <h3>
                    {queryYear}年 {queryMonth + 1}月 {queryDay}日
                  </h3>
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  as="select"
                  id="inlineFormCustomSelectPref"
                  custom
                  value={requiredRank}
                  onChange={(event) => {
                    setRequiredRank(event.target.value);
                  }}
                >
                  <option value="null">選擇所需員工</option>
                  <option value="RN">註冊護士(RN)</option>
                  <option value="EN">登記護士(EN)</option>
                  <option value="HCA">健康服務助理(HCA)</option>
                  <option value="HW">保健員(HW)</option>
                  <option value="PCW">起居照顧員(PCW)</option>
                  <option value="WM">常務員(WM)</option>
                </Form.Control>
              </Form.Group>
              <Form.Label>上班時間(24小時制)</Form.Label>
              <Form.Row>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="小時"
                    value={startHour}
                    onChange={(event) => {
                      setStartHour(event.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="分鐘"
                    value={startMinute}
                    onChange={(event) => {
                      setStartMinute(event.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Label>下班時間(24小時制)</Form.Label>
              <Form.Row>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="小時"
                    value={endHour}
                    onChange={(event) => {
                      setEndHour(event.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="分鐘"
                    value={endMinute}
                    onChange={(event) => {
                      setEndMinute(event.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Control
                  type="words"
                  placeholder="備註(如自定額外薪資)"
                  fullwidth
                  style={{ height: "3cm" }}
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                />
              </Form.Group>
              <Button
                variant="secondary"
                className="mb-3"
                onClick={() => {
                  pushArray();
                }}
              >
                新增
              </Button>
              <Button
                variant="secondary"
                className="mb-3 ml-4"
                onClick={() => {
                  setRequiredRank("");
                  setStartHour("");
                  setStartMinute("");
                  setEndHour("");
                  setEndMinute("");
                  setRemarks("");
                }}
              >
                清除全部
              </Button>
              {requestArray.length > 0 ? (
                <ol>
                  {requestArray.map((requestInfo, index) => {
                    return (
                      <li className="m-3">
                        {`職位:${requestInfo.rank}`} <br />{" "}
                        {`上班時間:${requestInfo.startTime.getFullYear()}年${requestInfo.startTime.getMonth() + 1
                          }月${requestInfo.startTime.getDate()}日${requestInfo.startTime.getHours() < 10
                            ? "0" + requestInfo.startTime.getHours()
                            : requestInfo.startTime.getHours()
                          }:${requestInfo.startTime.getMinutes() < 10
                            ? "0" + requestInfo.startTime.getMinutes()
                            : requestInfo.startTime.getMinutes()
                          }`}{" "}
                        <br />{" "}
                        {`下班時間:${requestInfo.endTime.getFullYear()}年${requestInfo.endTime.getMonth() + 1
                          }月${requestInfo.endTime.getDate()}日${requestInfo.endTime.getHours() < 10
                            ? "0" + requestInfo.endTime.getHours()
                            : requestInfo.endTime.getHours()
                          }:${requestInfo.endTime.getMinutes() < 10
                            ? "0" + requestInfo.endTime.getMinutes()
                            : requestInfo.endTime.getMinutes()
                          }`}
                        <br />
                        備註: {requestInfo.remarks}
                        <Button
                          variant="warning"

                          onClick={() => {
                            removeItem(index);
                          }}
                        >
                          移除
                        </Button>
                      </li>
                    );
                  })}
                </ol>
              ) : null}
              <Button
                variant="primary"
                className="float-right mt-5"
                disabled={requestArray.length == 0}
                onClick={() => {
                  getInstitutionInfoAndPostJobRequest();
                }}
              >
                發佈招聘
              </Button>
            </Form>
          </div>
        ) : (
          <div className="RequestRowContainer">
            <h3 className="mb-3">指派狀態</h3>
            <ul>
              {fetchArray.filter(request => new Date(request.startTime.seconds * 1000).getDate() == queryDay).map(request => {
                return (
                  <Card className="mb-3 p-3 RecruitedCard">
                    <h5>{fullRankTitle(request.rank)}</h5>
                    {`${new Date(request.startTime.seconds * 1000).getHours() < 10 ? '0' + new Date(request.startTime.seconds * 1000).getHours() : new Date(request.startTime.seconds * 1000).getHours()}時${new Date(request.startTime.seconds * 1000).getMinutes() < 10 ? '0' + new Date(request.startTime.seconds * 1000).getMinutes() : new Date(request.startTime.seconds * 1000).getMinutes()}分 - ${new Date(request.endTime.seconds * 1000).getHours() < 10 ? '0' + new Date(request.endTime.seconds * 1000).getHours() : new Date(request.endTime.seconds * 1000).getHours()}時${new Date(request.endTime.seconds * 1000).getMinutes() < 10 ? '0' + new Date(request.endTime.seconds * 1000).getMinutes() : new Date(request.endTime.seconds * 1000).getMinutes()}分`}<br />
                    <h6>備註:{request.remarks}</h6>
                    <h6>{request.appliedMembers.length == 0 ? "招聘中" : request.recruitedMembers.length > 0 ? "已配對" : "選擇應徵者"}</h6>
                    <Row>
                      {request.recruitedMembers.length > 0 ? null :
                        <>
                          <Button variant="warning" className="m-3" onClick={() => { pauseRecruiting(request.jobID) }}>暫停招聘</Button>
                          <Button variant="danger" className="m-3" onClick={() => { deleteRecruiting(request.jobID) }}>刪除招聘</Button>
                        </>}
                    </Row>
                    {request.appliedMembers.map(member => {
                      console.log("Member", member)
                      return (
                        <Row className="MembersRow">
                          {member.gender == "male" ? <Image className="Avatar" src={avatar} roundedCircle /> : <Image className="Avatar" src={femaleAvatar} roundedCircle />}
                          <p className="m-2"><b>{member.rank} {member.fullname}</b><br />
                            <span style={{ fontSize: 12 }}>最後檢測日期: {new Date(member.lastNCovid.seconds * 1000).getFullYear()}年{new Date(member.lastNCovid.seconds * 1000).getMonth()}月{new Date(member.lastNCovid.seconds * 1000).getDate()}日 檢測結果: {member.covidResult} 疫苗記錄: {member.vacination}</span>
                          </p>
                          {request.recruitedMembers.filter(recruited => recruited.email == member.email).length > 0 ? <Button variant="primary" className="m-3" disabled={true} onClick={() => { }}>將會上班</Button> : <Button variant="primary" className="m-3" onClick={() => { recruitMember(request.jobID, member) }}>招聘</Button>}

                        </Row>
                      )
                    })}
                  </Card>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
