import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Navbar, Nav, Spinner, Button } from "react-bootstrap/";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./homepage";
import Login from "./login";
import Register from "./register";
import About from "./about";
import Pricing from "./pricing";
import JobCalendar from "./calendar";
import Balance from "./balance";
import Contact from "./contact";
import Privacy_Policy from "./privacy_policy";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBFZER_Yasgst3RGirHX1DZ4UfiONmMaxU",
  authDomain: "galaxy-care-32fb5.firebaseapp.com",
  projectId: "galaxy-care-32fb5",
  storageBucket: "galaxy-care-32fb5.appspot.com",
  messagingSenderId: "869597643003",
  appId: "1:869597643003:web:fdfb4391ca6cd77910305e",
  measurementId: "G-BRB6QYH8ER",
};
export var Firebase = firebase.initializeApp(firebaseConfig);

function PrivateRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

function App() {
  const [appLoading, setAppLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    Firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
        setAppLoading(false);
      } else {
        setAppLoading(false);
      }
    });
  }, [user]);

  return (
    <div className="App">
      {appLoading ? (
        <div className="SpinnerContainer">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Router>
          <Navbar bg="light" variant="light" sticky="top">
            <Navbar.Brand href="/">Galaxy Care</Navbar.Brand>
            <Nav className="mr-auto">
              {user ? null : <Nav.Link href="/login">機構登入</Nav.Link>}
              {user ? <Nav.Link href="/calendar">指派工作</Nav.Link> : null}
              {user ? <Nav.Link href="/balance">月結記錄</Nav.Link> : null}
              {user ? <Nav.Link href="/pricing">價目表</Nav.Link> : null}
              <Nav.Link href="/about">服務介紹</Nav.Link>
              <Nav.Link href="/contact">聯絡我們</Nav.Link>
            </Nav>
            {user ? (
              <Button
                variant="secondary"
                class="signoutButton"
                onClick={() => {
                  Firebase.auth().signOut();
                  window.location.reload();
                }}
              >
                登出
              </Button>
            ) : null}
          </Navbar>
          <Switch>
            <Route
              exact
              path="/"
              component={HomePage}
            />
            <Route exact path="/register" component={Register}>
              {user ? <Redirect to="/calendar" /> : null}
            </Route>
            <Route path="/login" component={Login}>
              {user ? <Redirect to="/calendar" /> : null}
            </Route>
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
            <Route path="/privacy_policy" component={Privacy_Policy} />
            <PrivateRoute
              user={user}
              path="/calendar"
              component={JobCalendar}
            />
            <PrivateRoute user={user} path="/balance" component={Balance} />
            <PrivateRoute user={user} path="/pricing" component={Pricing} />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
