import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap/";
import { Firebase } from "./App";
import React, {useEffect} from "react";

export default function Pricing() {
  const hoursArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [currencyArray, setCurrencyArray] = React.useState([]);
  const [RNCurrency, setRNCurrency] = React.useState([]);
  const [ENCurrency, setENCurrency] = React.useState([]);
  const [PCWCurrency, setPCWCurrency] = React.useState([]);
  const [HWCurrency, setHWCurrency] = React.useState([]);
  const [WMCurrency, setWMCurrency] = React.useState([]);
  const [tableReady, setTableReady] = React.useState(false);

  useEffect(() => {
    document.title = "價目表 - 星玥護理";
  }, []);

  async function fetchCurrency(){
    var tmpArray = [];
    await Firebase.firestore().collection("institution_variables").get()
    .then(querySnapshot=>{
        querySnapshot.forEach(doc=>{
            const mergingObject = {id: doc.id};
            const merged = Object.assign(doc.data(), mergingObject)
            tmpArray.push(merged)
        })
        setCurrencyArray([...tmpArray]);
    })
    .catch(e=>{
        console.log("Error fetching currency", e)
    })
}

React.useEffect(async () => {
  await fetchCurrency();
}, [])

React.useEffect(async () => {
  if(currencyArray.length>0){
  setRNCurrency(currencyArray.filter(doc=>doc.id == "RN_Salary")[0]);
  setENCurrency(currencyArray.filter(doc=>doc.id == "EN_Salary")[0]);
  setPCWCurrency(currencyArray.filter(doc=>doc.id == "PCW_salary")[0]);
  setHWCurrency(currencyArray.filter(doc=>doc.id == "HW_Salary")[0]);
  setWMCurrency(currencyArray.filter(doc=>doc.id == "WM_salary")[0]);
  }
}, [currencyArray])

React.useEffect(()=>{
    setTableReady(true);
  
},[WMCurrency])

  return (
    <div className="p-5">
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>工作時數</th>
            <th>註冊護士(RN)</th>
            <th>登記護士(EN)</th>
            <th>健康服務助理(HCA)/保健員(HW)</th>
            <th>起居照顧員(PCW)</th>
            <th>常務員(WM)</th>
          </tr>
        </thead>
        {tableReady?
        <tbody>
          {hoursArray.map(hour=>{
            return(
              <tr>
                <td>{hour}</td>
                <td>{RNCurrency[hour]}</td>
                <td>{ENCurrency[hour]}</td>
                <td>{PCWCurrency[hour]}</td>
                <td>{HWCurrency[hour]}</td>
                <td>{WMCurrency[hour]}</td>
              </tr>
            )
          })}
        </tbody>:null}
      </Table>
      
    </div>
  );
}
