import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react'

export default function About() {
    useEffect(() => {
        document.title = "服務介紹 - 星玥護理";
      }, []);
    return (
        <div>
            <div class="jumbotron jumbotron-fluid jumbotron1">
                <div class="container">
                    
                </div>
            </div>
            <div class="container">
                <h2>服務範疇</h2>
                <p class="lead mb-5">成員秉承着公平、自主及誠懇的精神，讓護理對象得到最專業，適合的照顧，
                所以竭力向各機構、院舍提供護理人手及私家看護服務方案，冀望能解決人手
                短缺及護理對象家人的壓力。<br />
                    <ul>
                        <li>短期或長期的護理人員支援服務方案</li>
                        <li>私家看護服務方案</li>
                    </ul>
                    <b>護理人員</b>
                    <ul>
                        <li>註冊護士(RN)</li>
                        <li>登記護士(EN)</li>
                        <li>健康服務助理(HCA)</li>
                        <li>保健員(HW)</li>
                        <li>起居照顧員(PCW)</li>
                        <li>常務員(WM)</li>
                    </ul>
                </p>
            </div>
        </div>
    )
}