import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect} from "react";

export default function HomePage() {
  useEffect(() => {
    document.title = "首頁 - 星玥護理";
  }, []);

  return (
    <div>
      <div class="jumbotron jumbotron-fluid jumbotron1">
        <div class="container"></div>
      </div>
      <div class="container">
        <h2>關於星玥</h2>
        <p class="lead">
          星玥護理有限公司，由數名護理前線人員創立。現時醫院，NGO，私營院舍等對護理前線需求
          甚大。面對人口老化等問題，護理服務需求也愈來愈大，要舒緩此問題，要各方面配合才達目標。在資訊科技發達的年代，資訊科技為人們解決了不少問題，包括醫護方面上我的問題，所
          以星玥也希望能透過資訊科技，使護理的人力資源分配更平均，減輕各機構、院舍人手短缺及
          護理對象的家屬壓力，護理對象從而得到更專業、體貼、合適等的護理服務。
          藉此，星玥會以革新的模式，為機構/院舍及護理人員雙方配對工作。星玥會提供創新的應用程式
          Galaxy
          Care及網頁系統，讓雙方自由選擇配對工作，節省配對時間，打破地方界限。
          星玥以創新，公平，對等，效率，及誠懇為宗旨，希望能為整個護理行業提供貢獻。
        </p>
        <br />
      </div>
    </div>
  );
}
