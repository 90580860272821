import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect} from "react";
import { Form, Button, Card, Container } from "react-bootstrap/";
import { Link } from "react-router-dom";
import { Firebase } from "./App";

export default function Login() {
  const [email, onChangeEmail] = React.useState("");
  const [password, onChangePassword] = React.useState("");

  useEffect(() => {
    document.title = "機構登入 - 星玥護理";
  }, []);

  async function login(event) {
    event.preventDefault();
    await Firebase.firestore()
      .collection("institutions")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        console.log("QuerySnapshot", querySnapshot.size)
        if (querySnapshot.size > 0) {
          Firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then((userCred) => {
              console.log(userCred);
            })
            .catch((e) => {
              alert(e);
            });
        } else {
          alert("未有註冊記錄")
        }
      });
  }

  return (
    <Container>
    <div className="LoginBox">
      <div class="jumbotron jumbotron-fluid jumbotron2">
        <div class="container">
          <h2 class="display-4">星玥護理Galaxy Care</h2>
          <p class="lead">星玥會提供創新的應用程式及網址系統，以革新的模式，<br />為機構、院舍、護理
人員、護理對象等提供<br />自選的配對工作服務。</p>
        </div>
      </div>
      <Card>
        <Card.Body>
          <Form onSubmit={(event) => login(event)}>
            <Form.Group controlId="formBasicEmail">
              <h2>機構登入</h2>
              <Form.Label>您的電郵地址</Form.Label>
              <Form.Control
                type="email"
                placeholder="請輸入您的登記電郵地址"
                value={email}
                onChange={(event) => onChangeEmail(event.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>密碼</Form.Label>
              <Form.Control
                type="password"
                placeholder="密碼"
                value={password}
                onChange={(event) => {
                  onChangePassword(event.target.value);
                }}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              登入
            </Button>
            <Button
              variant="secondary"
              className="ml-4"
              onClick={() => {
                Firebase.auth().signOut();
              }}
            >
              <Link to="/register" className="RedirectLink">
                註冊
              </Link>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
    </Container>
  );
}
