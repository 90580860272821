import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

export default function Privacy_Policy() {
  return (
    <div>
      <div class="jumbotron jumbotron-fluid jumbotron1">
        <div class="container"></div>
      </div>
      <div class="container">
        <h2>私隱政策及收集個人資料聲明</h2>
        <p class="lead">
          Galaxy Care Limited星玥護理有限公司 下稱“星玥” 或“我們”)
          透過位於Galaxy Care 應用程式或任何其他透過Galaxy Care
          應用程式指示而進入並且經星玥授權以收
          集閣下個人資料向閣下收集個人資料或任何資料時，我們的政策是完全遵從規
          範處理個人資料的個人資料(私隱)條例(香港法例第486章)所述規定。個人資料
          是指與任何一名在世的個人有關的任何資料，而這些資料可用以識別該個人的
          身份。在處理個人資料時，星玥會確保屬下員工遵守香港法例所訂明的保安及
          保密標準。 <br />
          本聲明解釋星玥就收集、保留、儲存及共用個人資料的做法。 <br />
          <br />
          <b>1. 收集個人資料</b> <br />
          1.1 我們間中會向閣下收集個人資料
          (「收集的資料」)，包括但不限於閣下的姓名 、
          職銜、職位、地址、電話號碼(包括手機及傳真號碼)、電郵地址、地址、瀏覽器
          類別、域名、登入時間、其他聯絡資料，以及與閣下要求獲取資料或服務有關
          的其他人士的其他資料，並會將這些資料加以處理作當時說明的特定用途，或
          作本
          聲明或作應用程式使用及進入協議所述之任何用途。該等用途包括但不限於處
          理閣下的申請、投訴、查詢或回應，或用作識別及核實閣下的使用者身份。{" "}
          <br />
          1.2
          除了任何特定用途以外，我們會隨時使用收集的資料作內部統計調查及分析之
          用。 <br />
          1.3
          閣下確認及同意提供收集的資料屬自願性質。然而，未能提供我們要求的所有
          資料可能使我們無法全面或完全無法處理閣下的要求。 <br />
          1.4
          我們主要透過直接要求閣下填寫表格而取得收集的資料。我們的應用程式伺服
          器亦可能收集有關閣下使用互聯網的資料，以集體方式作伺服器使用量的綜合
          統計，藉
          以更能滿足我們網站訪客的需要及期望。此類資料包括（但不限於）： <br />1
          瀏覽器類型及版本； <br />2 操作系統；及 <br />3 互聯網規程 (IP)
          地址及／或網域名稱。 <br />
          <br />
          <b>2. 保安</b> <br />
          2.1
          我們會使用適當的安全加密系統透過本應用取得收集的資料。然而，我們強調
          沒有百份百安全的保安措施，因此其他人仍有可能截取閣下發送至或傳經本網
          站的資料。我們並不保證本網站不受黑客入侵或攻擊。 <br />
          2.2
          我們會採取適當措施以確保收集的資料在星玥保管期間的安全。這些措施包括
          利用密碼保護、或其他電子保安技術，及實物的保安方法。不論是以電子方式
          儲存於電腦內或保存於實物表格上的個人資料，只有由星玥授權的高級職員或
          員工
          取得。由我們指定負責處理收集的資料的任何人士，均會熟知本聲明，並獲告
          知如何履行我們向閣下承諾的責任。 <br />
          2.3 收集的資料只會在必要期間內保存，以供用於本聲明所述的用途。 <br />
          2.4
          除本聲明有所提供、在收集時告知閣下，或事先得到閣下的同意，我們向閣下
          收集的任何個人資枓均不會向任何第三方披露。 <br />
          2.5
          除在本應用程式指明外，我們不會出租、買賣或出售於網上收集的資料作商業
          用途。 <br />
          <br />
          <b>3. 超連結</b>
          <br />
          倘若閣下單擊一個超連結由本應用程式轉往另一個網站，我們對該網站的私隱
          及保安慣例或內容概不負責，閣下在進入每個網站時理應參看其私隱政策。{" "}
          <br />
          <br />
          <b>4. 披露收集的資料</b> <br />
          4.1
          除非經閣下事先同意或按照法律規定，我們不會向任何第三方轉移或披露收集
          的資料，惟下文所述者除外： <br />1
          為星玥可以提供閣下要求的服務而要在所需範圍內，就
          我們業務提供服務或意見的任何人員、代理人、顧問、核數師、承辦商或服務
          供應商； <br />2 須向我們承擔保密責任的任何人士；以及(c)
          根據任何政府機關發出的命令或
          要求或根據香港或香港以外的任何適用法律，我們須向其披露的任何人士或機
          構。 <br />
          4.2
          閣下理應注意，由於我們不能控制任何第三方的行為，任何資料根據我們的政
          策向第三方披露後，本聲明所述的私隱保障便不再適用。我們只會盡力與可靠
          的第三方進行業務往來，但對該等第三方提供的私隱保障概不負責。 <br />
          <br />
          <b>5. 查閱及更正資料</b> <br />
          5.1
          閣下有權查閱由我們保管有關閣下的任何收集的資料，如有需要，閣下亦有權
          更 正或更新該等資料。 <br />
          5.2 倘若閣下希望行使任何該等權利，請發送電郵至info@galaxycarehk.com。{" "}
          <br />
          <br />
          <b>6. 更改私隱政策及收集個人資料聲明</b> <br />
          6.1
          我們保留更改及修訂本聲明及其網上私隱政策的權利。然而，我們行使此權利
          時，必會將有關變更上載於本應用程式內。 <br />
          6.2 如星玥於任何時間決定以有別於本聲明所述方式來使用收集的資料，
          我們會於落實變更前1個月於本應用程式上載顯眼的通告以告知閣下。
          在我們以此不同的方式使用由閣下提供有關收集的資料前，我們需要閣下明示
          同意。
        </p>
        <br />
      </div>
    </div>
  );
}
